//TODO: implement
const logger = (error) => console.error(error);

export const serialize = (urls, args) =>
  urls.reduce((acc, d, i) => (acc += d + args[i]), "");

const URLS = {
  gasStation: {
    GET: [`https://api.gazstation.net/api/gasstation/`],
    BB: [
      `https://api.gazstation.net/api/gasstation/bounding?northeastLongitude=`,
      `&northeastLatitude=`,
      `&southwestLongitude=`,
      `&southwestLatitude=`,
    ],
  },
};

const getRequest = async (key, token, args) => {
  const urls = URLS[key][token];
  if (urls && urls.length) {
    try {
      const res = await fetch(serialize(urls, args));
      return await res.json();
    } catch (e) {
      return logger(e);
    }
  } else {
    throw new Error(`Bad request: ${token} for ${key} with ${args.join(", ")}`);
  }
};

export default {
  gasStation: {
    GET: (id) => getRequest("gasStation", "GET", [id]),
    BB: (neLon, neLat, swLon, swLat) =>
      getRequest("gasStation", "BB", [neLon, neLat, swLon, swLat]),
  },
};
