import React, { useState, useEffect, useRef, useCallback } from "react";
import { Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useLeaflet } from "react-leaflet";
import MarkerPopup from "./MarkerPopup.jsx";
import api from "../api";

const MemoizedMarker = React.memo(
  ({ data }) => (
    <Marker position={[data.latitude, data.longitude]}>
      <MarkerPopup data={data}></MarkerPopup>
    </Marker>
  ),
  // don't render when ids are the same
  (propOld, propNew) => propOld.data.id === propNew.data.id
);

export default function Markers() {
  const [data, setData] = useState();
  const { map } = useLeaflet();
  const bounds = useRef();
  const autoPanStarted = useRef(false);

  const getStations = useCallback(async (bound) => {
    const stations = await api.gasStation.BB(
      bound._northEast.lng,
      bound._northEast.lat,
      bound._southWest.lng,
      bound._southWest.lat
    );
    if (stations && stations.gasstations) {
      setData(stations.gasstations);
    }
  }, []);

  useEffect(() => {
    map.on("moveend", (e) => {
      const bb = e.target.getBounds();
      const animInProgress = map._panAnim && map._panAnim._inProgress;
      if (
        !autoPanStarted.current &&
        !animInProgress &&
        (!bounds.current || !bounds.current.equals(bb))
      ) {
        bounds.current = bb;
        getStations(bb);
      }
      autoPanStarted.current = false;
    });

    map.on("autopanstart", () => {
      autoPanStarted.current = true;
    });

    // map.on("load") won't work here
    map.whenReady(() => {
      if (!bounds.current) {
        bounds.current = map.getBounds();
        getStations(bounds.current);
      }
    });

    return () => {
      map.off("autopanstart");
      map.off("moveend");
      // whenReady can add "load" listener, better to clean it up
      map.off("load");
    };
  }, [getStations, map]);

  return (
    <MarkerClusterGroup>
      {data &&
        data.map((d) => <MemoizedMarker key={d.id} data={d}></MemoizedMarker>)}
    </MarkerClusterGroup>
  );
}
