import React, { useState, useEffect, useRef } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { withStyles } from "@material-ui/core/styles";
import { useCallback } from "react";

const HTMLPopup = withStyles((theme) => ({
  tooltip: {
    maxWidth: 500,
    margin: 0,
    padding: 0,
    border: null,
    pointerEvents: "auto",
  },
  arrow: {
    color: theme.palette.background.paper,
  },
}))(Tooltip);

function SelfDestructTooltip({ time, children, anchorEl }) {
  const [open, setOpen] = useState(true);
  const timeout = useRef();

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (open && time) {
      timeout.current = setTimeout(() => {
        setOpen(false);
      }, time);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
    }
    return () => clearTimeout(timeout.current);
  }, [open, time]);

  return (
    <HTMLPopup
      PopperProps={{
        disablePortal: true,
      }}
      arrow
      placement="bottom"
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={React.cloneElement(children, { onClose: handleTooltipClose })}
    >
      {anchorEl}
    </HTMLPopup>
  );
}

export default React.memo(SelfDestructTooltip);
