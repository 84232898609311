import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

const themedStyles = (theme) => ({
  linearContainer: {
    width: "100%",
    padding: `${theme.typography.fontSize - 4}px 0`,
  },
  circularContainer: {},
  center: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

const makeCSSClass = (cl1, cl2, rule, ruleKey) => {
  return `${cl1} ${cl2} ${rule[ruleKey] ? ruleKey : ""}`;
};

function Loader({
  loaderClass,
  containerClass,
  placement,
  children,
  type = "linear",
  loading,
  classes,
  ...rest
}) {
  const loaders = {
    linear: (
      <LinearProgress
        {...rest}
        className={loaderClass}
        styles={{ margin: "0 auto" }}
      />
    ),
    circular: <CircularProgress {...rest} className={loaderClass} />,
  };

  const cClass = (t) =>
    t === "linear" ? classes.linearContainer : classes.circularContainer;

  return loading ? (
    <div
      className={makeCSSClass(containerClass, cClass(type), {
        [classes.center]: placement === "center",
      })}
    >
      {loaders[type]}
    </div>
  ) : (
    <>{children}</>
  );
}

export default withStyles(themedStyles)(Loader);
