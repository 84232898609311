import React, { useMemo } from "react";
import SelfDestructTooltip from "../components/SelfDestructTooltip";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@material-ui/icons/Close";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const greenTheme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      main: green[500],
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
    },
    background: {
      paper: green[400],
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  header: {
    padding: theme.spacing(1.5),
  },
  content: {
    padding: theme.spacing(1.5),
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: theme.spacing(1.5),
    },
  },
  closeButton: {
    color: "#fff",
  },
}));

function PartnersPopupcontent({ onClose }) {
  const classes = useStyles();
  return useMemo(() => {
    return (
      <Card elevation={0} className={classes.root}>
        <CardHeader
          className={classes.header}
          action={
            <IconButton
              className={classes.closeButton}
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          title="Будьте нашим партнёром, если вы"
        />
        <CardContent className={classes.content}>
          <Typography variant="subtitle2">Владелец АЗС</Typography>
          <ul>
            <Typography variant="body2" component="li">
              Отвечайте на отзывы
            </Typography>
            <Typography variant="body2" component="li">
              Актуализируйте цены
            </Typography>
            <Typography variant="body2" component="li">
              Не становитесь жертвой народной модерации
            </Typography>
            <Typography variant="body2" component="li">
              Запускайте промоакции
            </Typography>
            <Typography variant="body2" component="li">
              Смотрите статистику
            </Typography>
          </ul>
          <Typography variant="subtitle2">Рекламодатель</Typography>
          <ul>
            <Typography variant="body2" component="li">
              Запускайте таргетированную рекламу
            </Typography>
          </ul>
        </CardContent>
      </Card>
    );
  }, [
    classes.closeButton,
    classes.content,
    classes.header,
    classes.root,
    onClose,
  ]);
}

export default function PartnersPopup() {
  return (
    <ThemeProvider theme={greenTheme}>
      <SelfDestructTooltip
        anchorEl={
          <Button
            href="https://web.gazstation.net/"
            style={{ position: "absolute", right: "0", marginRight: "20px" }}
            color="inherit"
          >
            Вход для партнеров
          </Button>
        }
        time={15000}
      >
        <PartnersPopupcontent></PartnersPopupcontent>
      </SelfDestructTooltip>
    </ThemeProvider>
  );
}
