import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Popup } from "react-leaflet";
import Loader from "../components/Loader";
import Grid from "@material-ui/core/Grid";
import PaymentIcon from "@material-ui/icons/Payment";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import Divider from "@material-ui/core/Divider";
import Rating from "@material-ui/lab/Rating";
import api from "../api";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(0.5, 0),
    flexWrap: "nowrap",
  },
  container: {
    flexWrap: "nowrap",
    width: "auto",
  },
  popup: {
    maxWidth: 500,
  },
  h5: {
    fontSize: 18,
    textAlign: "center",
  },
  h6: {
    fontSize: 12,
    padding: theme.spacing(0, 0.5),
  },
  p: {
    fontSize: 14,
    textAlign: "center",
  },
  subTitle: {
    fontSize: 12,
  },
  icon: {
    textAlign: "center",
    fontSize: 16,
    margin: "0 10px",
  },
  textEnabled: {
    color: theme.palette.grey[900],
  },
  textDisabled: {
    color: theme.palette.grey[300],
  },
  rating: {
    margin: "4px 0",
    display: "flex",
    justifyContent: "center",
  },
  ratingText: {
    alignItems: "center",
    display: "flex",
    fontSize: 19,
    margin: "0 5px",
  },
  detail: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
  },
}));

export default function MarkerPopup({ data }) {
  const gasStationRef = useRef();
  const [station, setStation] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const hasId = data && data.id;
    const shouldLoad =
      !gasStationRef.current || (hasId && gasStationRef.current.id !== data.id);

    if (hasId && shouldLoad && isOpen) {
      setLoading(true);
      api.gasStation.GET(data.id).then((gasStation) => {
        if (gasStation) {
          gasStationRef.current = gasStation;
          setLoading(false);
          setStation(gasStation);
        }
      });
      // value from cache
    } else if (hasId && isOpen) {
      setLoading(false);
      setStation(gasStationRef.current);
    } else {
      // fallback error
    }
  }, [data, isOpen]);

  return (
    <Popup
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      className={classes.popup}
    >
      <Grid container direction="column" className={classes.container}>
        <Typography className={classes.h5} variant="h5">
          {data.name}
        </Typography>
        <Typography
          gutterBottom
          color="textSecondary"
          component="span"
          className={`${classes.p} ${classes.subTitle}`}
        >
          {data.address}
        </Typography>

        <Loader
          placement="center"
          size={24}
          loading={isLoading}
          type="circular"
        >
          <Grid container justify="center" className={classes.root} spacing={2}>
            <Box className={classes.icon}>
              <PaymentIcon
                color={station?.cardAccepted ? "primary" : "disabled"}
              />
              <Typography
                className={`${classes.h6} ${
                  station?.cardAccepted
                    ? classes.textEnabled
                    : classes.textDisabled
                }`}
                variant="subtitle1"
              >
                Оплата картой
              </Typography>
            </Box>
            <Box className={classes.icon}>
              <LocalGasStationIcon
                color={station?.balloonRefill ? "primary" : "disabled"}
              />
              <Typography
                variant="subtitle1"
                className={`${classes.h6} ${
                  station?.balloonRefill
                    ? classes.textEnabled
                    : classes.textDisabled
                }`}
              >
                Заправка балонов
              </Typography>
            </Box>
            <Box className={classes.icon}>
              <CompareArrowsIcon
                color={station?.balloonExchange ? "primary" : "disabled"}
              />
              <Typography
                variant="subtitle1"
                className={`${classes.h6} ${
                  station?.balloonExchange
                    ? classes.textEnabled
                    : classes.textDisabled
                }`}
              >
                Обмен балонов
              </Typography>
            </Box>
          </Grid>
          <Divider></Divider>
          <div className={classes.rating}>
            <Rating
              name="stationRating"
              value={station?.rating || 0}
              size="large"
              readOnly
            />
            <Typography component="div" className={classes.ratingText}>
              {station?.rating}
            </Typography>
          </div>
          {station?.prices.map((p, i, arr) => (
            <Box
              className={classes.detail}
              key={p.typeName}
              justifyContent="space-between"
            >
              <Typography variant="body2" component="div">
                {p.typeName}
              </Typography>
              <Typography variant="body2" component="div">
                {p.price}&#8381;
              </Typography>
              {arr.length - 1 !== i ? <Divider /> : null}
            </Box>
          ))}
          <Typography
            gutterBottom
            color="textSecondary"
            component="span"
            className={`${classes.p} ${classes.subTitle}`}
          >
            {station?.worktime}
          </Typography>
        </Loader>
      </Grid>
    </Popup>
  );
}
