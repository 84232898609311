import React from "react";
import { Map, TileLayer, ZoomControl } from "react-leaflet";

import "react-leaflet-markercluster/dist/styles.min.css";
import "./App.css";
import Header from "./map/Header";
import Markers from "./map/Markers";

export default function App() {
  return (
    <div>
      <Header></Header>
      <Map
        center={[55.71679776058049, 37.38047814495799]}
        zoom={14}
        maxNativeZoom={15}
        zoomControl={false}
        minZoom={8}
        attributionControl={false}
      >
        <ZoomControl position="bottomleft" />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Markers></Markers>
      </Map>
    </div>
  );
}
